import React from 'react';
import Header from '../components/Header';
import {Helmet} from "react-helmet";
import Footer from "../components/Footer"


// Style
import './cgu.scss';


// markup
const Mentions = () => {

  return (
    <main className={"cgu"}>
      <Helmet title={'Scorf - Mentions légales'}>          
          <html lang="fr" />
          <meta charSet="utf-8" />
          <meta name='title' content='Scorf'/>
          <meta name="description" content="Scorf construit un outil BI unique capable de comprendre la 'grammaire' comptable qui se cache derrière chaque base de données financière - Grand livre, écritures de journal. Cela lui permet de fournir des analyses avancées et un suivi en temps réel aux départements financiers des PME ainsi qu'aux analystes et consultants financiers externes." />
          <link rel="canonical" href="https://www.scorf.io/mentions" />
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-63J6THBWF0"></script>
          <script dangerouslySetInnerHTML={{__html:`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-63J6THBWF0');`}}>
            
          </script>
      </Helmet>
      <title>Mentions légales</title>
      <Header headerColor={'white'}/>
      <h1>Mentions légales</h1>
      <p className="date">Dernière mise à jour le 29 mars 2021  </p>
      <div className="content">
        <h3>Éditeur du site Internet : </h3>
        <div className="line">
          <p>Dénomination sociale de l’éditeur : SCORF  </p>
        </div>
        <div className="line">
          <p>Statut de la société : Société par actions simplifiée </p>
        </div>
        <div className="line">
          <p>RCS : Nanterre n°853 834 059  </p>
        </div>
        <div className="line">
          <p>N° TVA intracommunautaire : FR6853834059  </p>
        </div>
        <div className="line">
          <p>Capital social : €8 000,00 </p>
        </div>
        <div className="line">
          <p>Siège social : 33 bis route de la Reine, 92100, Boulogne-Billancourt  </p>
        </div>
        <div className="line">
          <p>Adresse électronique : contact@scorf.io </p>
        </div>
        <div className="line">
          <p>Représentant légal : Ghislain de Robien, Président </p>
        </div>
        <div className="line">
          <p>Directeurs de la publication : Edouard de Nettancourt, Directeur Général </p>
        </div>
        <h3>Hébergeur du site Internet : </h3>
        <div className="line">
          <p>Nos serveurs sont hébergés en France sous la responsabilité de : </p>
        </div>
        <div className="line">
          <p>Microsoft Ireland Operations Ltd, One Microsoft Place, South County Business Park, Leopardstown, </p>
        </div>
        <div className="line">
          <p>Dublin 18, D18 P521, Ireland </p>
        </div>
        <div className="line">
          <p> <a href='https://azure.microsoft.com/fr-fr/' target='_blank' rel="noreferrer">https://azure.microsoft.com/fr-fr/</a> - Tél. : 1850 940 940 </p>
        </div>
        

      </div> 
      <Footer setHeaderColor={'white'} />
    </main>
  )
}

export default Mentions
